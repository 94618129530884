import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

export const sendFormData = createAsyncThunk('form/sendFormData', async (formData, { rejectWithValue }) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_REST_URL}send-email`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(formData),
    });

    if (!response.ok) {
      throw new Error('Failed to send email');
    }

    const result = await response.json();
    return result;
  } catch (error) {
    return rejectWithValue(error.message);
  }
});

const formSlice = createSlice({
  name: 'form',
  initialState: {
    formData: null,
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendFormData.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(sendFormData.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.formData = action.payload;
      })
      .addCase(sendFormData.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload;
      });
  },
});

export default formSlice.reducer;
