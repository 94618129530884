import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { useButton } from '@react-aria/button';

/** Components */
import Icon from './Icon';

const Button = forwardRef((props, buttonRef) => {
  const { buttonProps } = useButton(props, buttonRef);
  const { children, icon } = props;
  const ariaProps = {
    'aria-label': props.ariaLabel || null,
  };

  return (
    <button
      ref={buttonRef}
      {...buttonProps}
      {...ariaProps}
      className={`button ${props.className} ${props.layout ? `l-${props.layout}` : ''}`}
      onMouseEnter={(e) => props.onMouseEnter(e)}
      onMouseLeave={(e) => props.onMouseLeave(e)}
    >
      {children ? children : icon && <Icon icon={icon} layout={props.iconLayout} />}
    </button>
  );
});

Button.displayName = 'Button';

/**
 * `children` and `icon` props can't be set together.
 * Don't forget to provide an `ariaLabel` prop if you provide the `icon` prop.
 */
Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  icon: PropTypes.string,
  layout: PropTypes.oneOf(['square-outline', 'plain']),
  iconLayout: PropTypes.string, // @see Icon.propTypes
  onPress: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  ariaLabel: PropTypes.string,
};

Button.defaultProps = {
  className: '',
  onMouseEnter: () => {},
  onMouseLeave: () => {},
};

export default Button;
