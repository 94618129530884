import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SocialX from '../images/SocialX';
import SocialFb from '../images/SocialFb';
import SocialIn from '../images/SocialIn';
import SocialMail from '../images/SocialMail';
import IconPrint from '../images/icon-print';
import IconShare from '../images/icon-share';

const SocialShareButton = ({ url, title, message, image, displayType, buttons }) => {
  const { t: __ } = useTranslation();
  const [showTooltip, setShowTooltip] = useState(false);

  const postMessageLink = url ? `${message} - ${url}` : message;
  const postMessageWithImage = image ? `${message} - ${image}` : postMessage;

  const shareToFacebook = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`;
  const shareToX = `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(
    postMessageWithImage
  )}`;
  const shareToLinkedIn = `https://www.linkedin.com/feed/?shareActive=true&text=${encodeURIComponent(postMessageLink)}`;
  const shareToEmail = `mailto:?subject=${encodeURIComponent(title)}&body=${encodeURIComponent(postMessageLink)}`;
  const handlePrint = () => {
    window.print();
  };

  const tooltipStyle = {
    visibility: showTooltip ? 'visible' : 'hidden',
    position: 'absolute',
    bottom: displayType === 'icon' ? '70%' : '110%',
    left: displayType === 'icon' ? '-5%' : '1%',
    transform: 'translateX(-50%)',
    backgroundColor: '#333333',
    color: 'white',
    padding: '10px 10px',
    borderRadius: '6px',
    fontSize: '0.75rem',
    whiteSpace: 'nowrap',
    zIndex: 1000,
  };

  return (
    <div>
      {displayType === 'text' && (
        <>
          <a className="fb-link" href={url} target="_blank" rel="noreferrer">
            {__('templates.feeds.View on facebook')}
          </a>
          &nbsp;-&nbsp;
        </>
      )}
      <div
        role="presentation"
        onMouseOver={() => setShowTooltip(true)}
        onFocus={() => setShowTooltip(true)}
        onMouseOut={() => setShowTooltip(false)}
        onBlur={() => setShowTooltip(false)}
        className="share-button"
      >
        {displayType === 'icon' ? (
          <IconShare />
        ) : (
          <span style={{ cursor: 'pointer' }}>{__('templates.feeds.share')}</span>
        )}
        <div className="tooltip" style={tooltipStyle}>
          {buttons.includes('facebook') && (
            <a href={shareToFacebook} target="_blank" rel="noopener noreferrer">
              <SocialFb className="social-share" />
            </a>
          )}
          {buttons.includes('twitter') && (
            <a href={shareToX} target="_blank" rel="noopener noreferrer">
              <SocialX className="social-share" />
            </a>
          )}
          {buttons.includes('linkedin') && (
            <a href={shareToLinkedIn} target="_blank" rel="noopener noreferrer">
              <SocialIn className="social-share" />
            </a>
          )}
          {buttons.includes('email') && (
            <a href={shareToEmail}>
              <SocialMail className="social-share" />
            </a>
          )}
          {buttons.includes('printable') && (
            <button
              type="button"
              onClick={handlePrint}
              style={{ background: 'none', border: 'none', padding: 0, cursor: 'pointer' }}
            >
              <IconPrint className="social-share" fill={'#ffffff'} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

SocialShareButton.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
  image: PropTypes.string,
  displayType: PropTypes.oneOf(['icon', 'text']),
  buttons: PropTypes.arrayOf(PropTypes.string),
};

SocialShareButton.defaultProps = {
  url: '',
  title: '',
  message: '',
  image: '',
  displayType: 'text',
  buttons: ['facebook', 'twitter', 'linkedin', 'email', 'prinable'],
};

export default SocialShareButton;
